//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import imgdown from "@/assets/icon-down.png";
import imgdowngray from "@/assets/icon-down-gray.png";
import imgdate from "@/assets/icon-date.png";
import imgfilter from "@/assets/icon-filter.png";

export default {
  name: "storeDailyReportDetail",
  data() {
    return {
      businessChannelOptions: [
        {
          label: "直营正价",
          value: "202",
        },
        {
          label: "直营奥莱",
          value: "203",
        },
        {
          label: "加盟",
          value: "201",
        },
      ],
      
      sumInfo: {
        two_years_ago_month_sales: 0,
        before_day_sales: 0,
        before_month_sales: 0,
        month_target: 0,
        year_target: 0,
      },
      minDate: new Date(2023, 6, 1),
      maxDate: new Date(),
      keywords: "",
      imgfilter,
      imgdown,
      imgdate,
      imgdowngray,
      brand: "",
      currentDate: new Date(),
      currentFormatDate: this.$moment(new Date()).format("yyyy-MM"),
      showPopover: false,
      dailyList: [],
      staticSaleMap: {},
      storeNum: 0,
      noDataStoreList: [],
      managerName: "",
      analyseList: [],
      analysePopup: false,
      storeInfos: {},
    };
  },
  mounted() {
    console.log(this.$route);
    if (this.$route.params && this.$route.params.infos) {
      this.storeInfos = this.$route.params.infos;
      this.currentDate = this.$moment(this.storeInfos.datetime, "yyyy-MM-DD");
      this.currentFormatDate = this.$moment(this.storeInfos.datetime, "yyyy-MM-DD").format("yyyy-MM");
      this.days = this.$moment(new Date(), "yyyy-MM").daysInMonth();
      this.day = 0;
      window.document.title = "门店日报汇总";
      this.getDailyList();
    } else {
      // this.$router.go(-1)
      console.log(222);
    }
  },
  computed: {
    managerList() {
      return this.dailyList.filter((v) => {
        return v.saleManager == this.managerName;
      });
    },
  },
  methods: {
    //获取详情
    getStoreDaySalesAnalyseList(id) {
      const that = this;
      let postData = {
        queryMapObject: {},
      };
      if (id) {
        postData.queryMapObject.id = id;
        this.axios
          .post("/consumer/store/daysale/getStoreDaySalesAnalyseList", postData)
          .then((res) => {
            console.log(res);
            if (res.data && res.data.data) {
              that.analyseList = res.data.data.filter((v) => {
                v.businessChannelName = ""
                this.businessChannelOptions.map(item=>{
                  if(item.value == v.businessChannel){
                    v.businessChannelName = item.label
                  }
                })
                return v.remark;
              });
              that.analysePopup = true;
            }
          });
      } else {
        that.analyseList = that.dailyList.filter((v) => {
          return v.remark;
        });
        that.analysePopup = true;
      }
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      }
      if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    chooseDate(e) {
      console.log(e);
      this.currentDate = e;
      this.currentFormatDate = this.$moment(e).format("yyyy-MM");
      this.days = this.$moment(e, "YYYY-MM").daysInMonth();
      this.day = 0;
      this.getDailyList();
      this.showPopover = false;
    },

    getDailyList() {
      const that = this;
      let postData = {
        queryMapObject: {
          dateTime: this.$moment(
            this.currentFormatDate +
              "-" +
              (this.day < 9 ? "0" + (this.day + 1) : this.day + 1)
          ).format("yyyy-MM"),
          brand: this.storeInfos.brand,
          storeId: this.storeInfos.storeId,
        },
      };
      this.axios
        .post(
          "/consumer/store/daysale/getStoreDaySaleDetailList",
          postData
        )
        .then((res) => {
          console.log(res);
          if (res.data && res.data.data) {
            res.data.data.dataStoreList.map((v) => {
              v.labelNames = (v.labelNames && v.labelNames.split(",")) || [];
              v.open = false;
              v.goodsOrderRate =
                v.goodsNum == 0 || v.ordersNum == 0
                  ? 0
                  : (v.goodsNum / v.ordersNum).toFixed(2);
              v.businessChannelName =""
              this.businessChannelOptions.map(item=>{
                if(item.value == v.businessChannel){
                  v.businessChannelName = item.label
                }
              })
            });
            this.dailyList = res.data.data.dataStoreList;
            this.staticSaleMap = res.data.data.staticSaleMap;
            this.storeNum = res.data.data.storeNum;
            this.noDataStoreList = res.data.data.noDataStoreList;
            this.sumInfo = {
              two_years_ago_month_sales: 0,
              before_day_sales: 0,
              before_month_sales: 0,
              month_target: 0,
              year_target: 0,
            };
            if (this.dailyList.length > 0) {
              this.getTargetInfo();
            }
          }
        });
    },
    getTargetInfo() {
      let requestData = [
        {
          SIGN: "I",
          OPTION: "BT",
          PARAMETER: "Z0CALDAY_MVIM_001",
          LOW: this.$moment(
            this.currentFormatDate +
              "-" +
              (this.day < 9 ? "0" + (this.day + 1) : this.day + 1)
          ).startOf('month').format("yyyy-MM-DD"),
          HIGH: this.$moment(
            this.currentFormatDate +
              "-" +
              (this.day < 9 ? "0" + (this.day + 1) : this.day + 1)
          )
            .endOf("month")
            .format("yyyy-MM-DD"),
          LOW_FLAG: "1",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0INDUSTRY_MVOO_001",
          LOW: this.storeInfos.brand,
          HIGH: "",
          LOW_FLAG: "1",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZZSTORENEW_MVOO_001",
          LOW: this.storeInfos.storeId,
          HIGH: "",
          LOW_FLAG: "1",
        },
      ];
      this.axios
        .post("/consumer/bw/BwApi/storedailyreport_getSalesTarget", requestData)
        .then((res) => {
          let sumInfo = {
            two_years_ago_month_sales: 0,
            before_day_sales: 0,
            before_month_sales: 0,
            month_target: 0,
            year_target: 0,
          };
          console.log("storedailyreport_getSalesTarget", res);
          if (res && res.data && res.data.data) {
            let temp = res.data.data;
            temp.map((v) => {
              console.log(v);
              sumInfo.two_years_ago_month_sales += Number(
                v.two_years_ago_month_sales
              );
              sumInfo.before_day_sales += Number(v.before_day_sales);
              sumInfo.before_month_sales += Number(v.before_month_sales);
              sumInfo.month_target += Number(v.month_target);
              sumInfo.year_target += Number(v.year_target);
            });
          }
          this.sumInfo = sumInfo;
        });
    },
    getNumber(val) {
      let num = Number(val);
      let str = "0";
      if (isNaN(num) || num == "NaN" || num == Infinity || num == -Infinity) {
        return "0";
      } else {
        return (
          Math.floor(num * Math.pow(10, num < 10000 ? 1 : 0)) /
          Math.pow(10, num < 10000 ? 1 : 0)
        );
      }
    },
    getRate(value, total) {
      if (
        isNaN(total) ||
        total == "NaN" ||
        total == Infinity ||
        total == -Infinity
      ) {
        return "-";
      }
      if (total == 0) {
        return "0";
      }
      let val = (parseFloat(value / total) * 100).toFixed(1);
      if (isNaN(val) || val == "NaN" || val == Infinity || val == -Infinity) {
        return "-";
      } else {
        return val + "";
      }
    },
  },
};
